import React from 'react';
import Navbar from '../../Components/Main/navbar';
import Banner from '@/Components/Main/banner';
import AboutSection from '@/Components/Main/about';
import Footer from '@/Components/Main/footer';
import Banner2 from '@/Components/Main/banner2';
import AnimalCards from '@/Components/Main/animalcards';
import Cards from '@/Components/Main/cards';
import { Link, usePage, Head } from '@inertiajs/react';



import { useInView } from 'react-intersection-observer';

export default function Home({about, sliders, kategoria, kategorie,zvierata,sekciazviera, appsection, footer}) {
    const navbarRef = useInView({ triggerOnce: true, threshold: 0.3 });
    const bannerRef = useInView({ triggerOnce: true, threshold: 0.3 });
    const aboutSectionRef = useInView({ triggerOnce: true, threshold: 0.3 });
    const animalCardsRef = useInView({ triggerOnce: true, threshold: 0.3 });
    const cardsRef = useInView({ triggerOnce: true, threshold: 0.1 });
    const banner2Ref = useInView({ triggerOnce: true, threshold: 0.3 });
    const footerRef = useInView({ triggerOnce: true, threshold: 0.3 });

    const { menuData } = usePage().props;
    const { auth } = usePage().props;

    return (
        <>
            <Head title="Domovská stránka" />
            <div ref={navbarRef.ref} className={`fade-in-section ${navbarRef.inView ? 'is-visible' : ''}`}><Navbar menu={menuData} auth={auth} /></div>
            <div ref={bannerRef.ref} className={`fade-in-section ${bannerRef.inView ? 'is-visible' : ''}`}><Banner sliders={sliders} /></div>
            <div ref={aboutSectionRef.ref} className={`fade-in-section ${aboutSectionRef.inView ? 'is-visible' : ''}`}><AboutSection about={about} /></div>
            <div ref={animalCardsRef.ref} className={`fade-in-section ${animalCardsRef.inView ? 'is-visible' : ''}`}><AnimalCards kategoria={kategoria} kategorie={kategorie} /></div>
            <div ref={cardsRef.ref} className={`fade-in-section ${cardsRef.inView ? 'is-visible' : ''}`}><Cards zvierata={zvierata} sekciazviera={sekciazviera} /></div>
            <div ref={banner2Ref.ref} className={`fade-in-section ${banner2Ref.inView ? 'is-visible' : ''}`}><Banner2 appsection={appsection} /></div>
            <div ref={footerRef.ref} className={`fade-in-section ${footerRef.inView ? 'is-visible' : ''}`}><Footer footer={footer} kategorie={kategorie} /></div>
        </>
    );
}
