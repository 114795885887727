import React from 'react';

const AnimalCards = ({kategoria,kategorie}) => {

  return (
    <div className="mx-auto container px-4 py-8 text-center">
      <h2 className="text-4xl font-bold text-[#26303D]">{kategoria.title}</h2>
      <p className="text-[#667085] max-w-2xl mx-auto">{kategoria.description}</p>
      <div className="md:flex justify-between md:space-x-12 mt-6">
        {kategorie.map((animal) => (
          <a href="/zoznam" key={animal.name} className="flex md:flex-col items-center my-4 md:my-0 md:w-1/3" >
            <div className="bg-cover bg-center rounded-2xl shadow-md w-full h-48"
              style={{
                backgroundImage: `url(/storage/${animal.fotka})`
              }}
            >
              <div className="flex items-center justify-end h-full w-full p-4 px-8 rounded-2xl transition hover:bg-[#07112C]/20" style={{
                backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0) 41.51%, rgba(7, 17, 44, 0.62) 100%)`
              }}>
                <h3 className="text-white text-3xl font-semibold">{animal.name}</h3>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default AnimalCards;
