import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Banner = ({ sliders }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        adaptiveHeight: true,
        arrows: false
    };

    return (
        <section className="container mx-auto my-4 px-4">
            <Slider {...settings}>
                {sliders.map((slider, index) => (
                    <div key={index} className="relative h-64 sm:h-96 md:h-128 lg:h-144">
                        <div
                            className="absolute inset-0 rounded-xl overflow-hidden bg-cover bg-center bg-no-repeat"
                            style={{ backgroundImage: `url('/storage/${slider.image}')` }}
                        >
                            <div className="p-8 md:p-12 lg:px-16 lg:py-24 rounded-xl bg-black/20 md:bg-transparent h-full flex items-center justify-start">
                                <div className="text-center sm:text-left">
                                    <h2 className="text-2xl max-w-lg font-bold text-white sm:text-3xl md:text-5xl">
                                        {slider.title}
                                    </h2>
                                    <p className="max-w-lg text-white/90 md:mt-6 md:block md:text-lg md:leading-relaxed">
                                        {slider.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </section>
    );
};

export default Banner;
