import React from 'react';

const Cards = ({sekciazviera,zvierata}) => {

    return (
        <div className="mx-auto container px-4 py-8 text-left  text-center">
            {/*<div className="text-4xl font-bold text-[#26303D]">
                <a href={sekciazviera.link} className='bg-[#BE1622] hidden md:block float-right text-sm font-normal rounded-3xl text-white px-8 py-3 transition hover:bg-[#BE1622]/80'>
                    Zobraziť všetky zvieratá
                </a>
                {sekciazviera.title}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mt-6">
                {zvierata.map((animal) => (
                    <a href={`/zoznam/${animal.slug}`} key={animal.meno} className="flex items-center" >
                        <div className="bg-cover bg-center rounded-2xl shadow-md w-full h-64"
                            style={{
                                backgroundImage: `url(/storage/${animal.fotka})`
                            }}
                        >
                            <div className="flex items-end justify-start h-full w-full p-8 rounded-2xl transition hover:bg-[#07112C]/20 relative" style={{
                                backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 41.51%, rgba(7, 17, 44, 0.62) 100%)`
                            }}>
                                {animal.isPatron && (
                                <div className='absolute flex items-center gap-2 right-0 m-6 px-4 py-2 top-0 text-[#BE1622] text-sm bg-[#FFD4D8] rounded-3xl font-semibold'>
                                    <img src={`/storage/logo-ikona.png`} alt="ikona" className='h-6' />
                                    Som patrónom
                                </div>
                                )}
                                <h3 className="text-white text-3xl font-semibold">{animal.meno}</h3>
                            </div>
                        </div>
                    </a>
                ))}
            </div>*/}
            <a href={sekciazviera.link} className='bg-[#BE1622] text-2xl font-normal rounded-3xl text-white px-8 py-3 transition hover:bg-[#BE1622]/80'>
                    Zobraziť všetky zvieratá
                </a>
        </div>
    );
};

export default Cards;
